import { reactive, provide, inject } from 'vue';

import { globalStore }    from '@ozdao/prometheus-framework/modules/globals/client';
import { authStore }      from '@ozdao/prometheus-framework/modules/auth/client';

const globals = globalStore

const auth = authStore

class Store {  
  constructor() {  
    if (!Store.instance) {  
      this.store = reactive({  
        globals: globals,  
        auth: auth,  
        modules: {}, // Инициализация пустого объекта для модулей  
        addStore: this.addStore.bind(this) // Привязка контекста  
      });  
      Store.instance = this;  
    }  
    return Store.instance;  
  }

  addStore(name, storage) {  
    this.store[name] = storage;  
  }  
}

const instance = new Store();  

Object.freeze(instance);

export default instance.store;

export function useStore() {
  return instance.store
}