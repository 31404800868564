import { createI18n, useI18n } from 'vue-i18n'

// Auth
import SignIn from './auth/SignIn.json' 


// HOME PAGE
import header     from './header.json'
import motto      from './motto.json'
import space      from './space.json'
// import contacts   from './contacts.json'
import footer     from './footer.json'

// GALLERY
import gallery    from './gallery.json'

// COMMUNITY
import community  from './community.json'
import comments   from './comments.json'

const supportedLocales = {
  en: "🌎 EN",
  ru: "🇷🇺 RU",
  zh: "🇨🇳 ZH",
  fr: "🇫🇷 FR",
  de: "🇩🇪 DE",
  it: "🇮🇹 IT"
}

export function getSupportedLocales() {
  let annotatedLocales = []

  for (const code of Object.keys(supportedLocales)) {
    annotatedLocales.push({
      code,
      name: supportedLocales[code]
    })
  }

  return annotatedLocales
}

export function getBrowserLocale(options = {}) {
  const defaultOptions = { countryCodeOnly: false }

  const opt = { ...defaultOptions, ...options }

  const navigatorLocale =
    navigator.languages !== undefined
      ? navigator.languages[0]
      : navigator.language

  if (!navigatorLocale) {
    return undefined
  }

  const trimmedLocale = opt.countryCodeOnly
    ? navigatorLocale.trim().split(/-|_/)[0]
    : navigatorLocale.trim()

  return trimmedLocale
}

// Объединяем все JSON модули в один объект
const messages = {}

// добавляем содержимое каждого языкового словаря в messages
for (const locale of Object.keys(supportedLocales)) {
  messages[locale] = {
    ...((header[locale] || {})),
    ...((SignIn[locale] || {})),
    ...((motto[locale] || {})),
    ...((space[locale] || {})),
    ...((footer[locale] || {})),
    // GALLERY
    ...((gallery[locale] || {})),
    // COMMUNITY
    ...((community[locale] || {})),
    ...((comments[locale] || {}))
  }
}

const i18n = createI18n({
  locale: 'en',
  fallbackLocale: "en-default",
  legacy: false,
  globalInjection: true,
  messages: messages,
  availableLocales: getSupportedLocales()
})

export default i18n;
