<template>
	<div class="pd-small error-wrapper">
		<div class="
			flex-nowrap flex
			radius-small
			pd-small
			t-white 
			bg-fourth
		">
			<p class="w-100">
				{{store.globals.state.error.message}}
				<!-- <span class="t-semi">Ошибка: {{status}} </span> -->
				<br>
				<!-- <span>Сообщение: {{message}}</span> -->
			</p>
			<!-- <img @click="closeError()" class="i-small" src="@/assets/icons/cross-white.svg"> -->
		</div>
	</div>
</template>


<script setup="props">
	import {computed } from 'vue'
	import { useStore } from '@/store'

	const store = useStore()
	
	const props = defineProps({
	  status: String,
	  message: String,
	  show: Boolean
	})

	function closeError () {
		store.globals.state.error.show = false;
		// store.commit('closeErrorNotification')
	} 
</script>

<style lang="scss">
	.error-wrapper {
		position: fixed;
		left: 0;
		right: 0;
		opacity: 0.9
	}
</style>


