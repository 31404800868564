const landing = [
 {
    name: 'Home',
    path: '',
    component: () => import(/* webpackChunkName: 'Home' */ '@/components/pages/Home.vue'),
  },{
    name: 'Gallery',
    path: 'gallery',
    component: () => import(/* webpackChunkName: 'Gallery' */ '@/components/pages/Gallery.vue'),
  },{
    name: 'Menu',
    path: 'menu',
    component: () => import(/* webpackChunkName: 'Menu' */ '@/components/pages/Menu.vue'),
  },{
    name: 'About',
    path: 'about',
    component: () => import(/* webpackChunkName: 'About' */ '@/components/pages/About.vue'),
  },{
    name: 'Contacts',
    path: 'contacts',
    component: () => import(/* webpackChunkName: 'Contacts' */ '@/components/pages/Contacts.vue'),
  }
];

export default landing;