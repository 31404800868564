<template>
   <footer id="footer" class="t-uppercase flex-center flex t-black pd-big" data-scroll-section>
    <p class="uppercase t-medium w-100 ">
      {{ $t('footer.year') }} 
      <span class="t-main copyleft">{{ $t('footer.heart') }}</span> 
      {{ $t('footer.name') }}. {{ $t('footer.tagline') }} 
      <a href="https://ozdao.dev" class="t-main" target="_blank" rel="noopener" >OZ DAO.</a>
    </p>
  </footer>
</template>

<script setup>
</script>

<style lang="scss">
  #footer {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
</style>
