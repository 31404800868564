<template>
	<header ref="headerRef" class="z-index-3 bg-white page-header pd-medium">
   
		<router-link class="header-logo" to="/">
			<img class="desktop-only" 	src="@/assets/images/logotype.svg">
			<img class="mobile-only i-thin"  	src="@/assets/images/logo.svg">
		</router-link>

		<nav class="t-black flex-center gap-thin flex">
      <router-link class="t-black t-medium" to="/menu">{{$t('navigation.menu')}}</router-link>
      <router-link class="t-black t-medium" to="/gallery">{{$t('navigation.gallery')}}</router-link>
      <router-link class="t-black t-medium" to="/about">{{$t('navigation.about')}}</router-link>
      <router-link class="t-black t-medium" to="/contacts">{{$t('navigation.contacts')}}</router-link>

			<Select 
        :options="[
        	'en','ru','zh','fr','de','it'
        ]"
        v-model:select="$i18n.locale" 
				class="bg-white br-solid br-black-transp br-1px uppercase pd-thin t-semi bg-white t-black radius-thin"
      />
		</nav>

	</header>
</template>

<script setup scoped>
/////////////////////////////
// COMPONENT DEPENDENCIES
/////////////////////////////
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router'
import { useRoute } from 'vue-router'
// GSAP DEPENDENCIES
import { gsap } from "gsap/dist/gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { useStore } from '@/store';

import { Select } from '@ozdao/prometheus-framework'
/////////////////////////////
// HELPERS
/////////////////////////////
function checkMaxWidth (width) {    
  return window.matchMedia('(max-width: ' + width + ')').matches;
}
function checkClass(cssClass, element) {    
  return element.classList.contains(cssClass)
}
/////////////////////////////
// CREATED
/////////////////////////////
const store = useStore()
const route = useRoute();
const router = useRouter()

gsap.registerPlugin(ScrollTrigger);

const headerRef = ref(null)

</script>

<style lang="scss">
.page-header {
	display: flex;
	position: sticky;

	flex-wrap: wrap;

	width: 100%;
	height: 6rem;


  top:0;  

	justify-content: space-between;
	align-items: center;
	
	.header-logo img { height: 1.5rem;} 

	transition: all 0.33s cubic-bezier(.08,.75,.77,1.03);
}

</style>
