import { gsap } from "gsap/dist/gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

export default function init() {
  
  // ANY ELEMENT INTRO GENERIC
  const elementsIntro = document.querySelectorAll(".element-intro");

  ScrollTrigger.config({
    ignoreMobileResize: true,
    autoRefreshEvents: "visibilitychange,DOMContentLoaded,load" // notice "resize" isn't in the list
  });
  
  ScrollTrigger.addEventListener('refresh', () => console.log('refreshed !'))

  gsap.set(elementsIntro, {autoAlpha: 0, y: 50 });
  
  ScrollTrigger.batch(elementsIntro, {
    onEnter: batch => {
      batch.forEach((card, index) => gsap.to(card, {
        autoAlpha: 1,
        y: 0,
        duration: 1,
        stagger: 0.1,
        // ease: "Circ.easeInOut",
      }))
    },
    start: "top bottom"
  })
  // DIVIDER INTRO GENERIC
  const linesIntro = document.querySelectorAll(".lines-intro");

  gsap.set(linesIntro, {width: 0 });

  linesIntro.forEach( (el)=> {
    gsap.to(el, {
      width: "100%",
      autoAlpha: 1,
      duration: 1,
      scrollTrigger: {trigger: el, } 
    }, "<+=0.125");
  })

  // TEXT INTRO BY WORDS
  var textFadeIn = document.querySelectorAll(".text-fade-in");
  var textSplitted = document.querySelectorAll(".text-splitted");

  textSplitted.forEach( (el)=> {
    var letters = el.querySelectorAll(".letter");

    gsap.set(letters, {
      y: 40,
      x: 25,
      skewX: 10,
      autoAlpha: 0,
    }); 

    ScrollTrigger.batch(el, {
      onEnter: batch => {
        batch.forEach((card, index) => {gsap.to(letters, {
          autoAlpha: 1,
          y: 0,
          x: 0,
          skewX: 0,
          duration: 1,
          stagger: 0.1,
        })

      }
        )
      },
      // start: "top 75%",
    })
  });
  
} 
