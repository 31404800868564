// //////////////////////////////////////////////////////
// Modules
// //////////////////////////////////////////////////////
import { createSSRApp } from "vue";
import { createWebHistory } from "vue-router";
// App Instance Creation Function
import { _createApp } from './app';
// Get user browser locale
import getBrowserLocale from "@/localization/methods/get-browser-locale"

// //////////////////////////////////////////////////////
// Init objects
// //////////////////////////////////////////////////////
const { app, router, store, i18n, meta, metaPlugin } = _createApp();

const browserLocale = getBrowserLocale({ countryCodeOnly: true })

// //////////////////////////////////////////////////////
// Dehydration Function
// //////////////////////////////////////////////////////
const start = async () => {
  // Parse server side user state
  let userState;

  try {
    const userStateElement = document.querySelector("[data-user-state]");
    if (userStateElement && userStateElement.innerHTML.trim() !== "") {
      console.log(userStateElement.innerHTML.trim());  // Add this line
      userState = JSON.parse(userStateElement.innerHTML.trim());
    }
  } catch (error) {
    console.error("Failed to parse user state", error);
  }

  // Continue your logic here
  if (userState) {
    store.auth.state.user = userState;
    store.auth.state.access.status = true;
  }
  // If user browser locae supported then change locale
  // if (i18n.global.availableLocales.includes(browserLocale)) {
  //   app.config.globalProperties.$i18n.locale = browserLocale;
  // }
  // Check is page loaded
  await router.isReady();
  // Dehydrate and mount
  app.mount("#app");
};

// //////////////////////////////////////////////////////
// Dehydration
// //////////////////////////////////////////////////////
start();