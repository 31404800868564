<template>
	<div  class="cols-2 gap-thin  pos-relative flex-center flex w-100 ">
		<div class="o-hidden radius-big w-100 h-100 pos-relative">
    	<img class="pos-absolute pos-t-0 pos-l-0 w-100 object-fit-cover h-100" src="/pictures/photo_15.jpg">
		</div>

    <div class=" pd-big  bg-main radius-big flex-column flex gap-small">
      <Field 
        v-model:field="bookingName"     
        placeholder="Enter name for booking"  
        class="
          bg-white
          pd-medium
          radius-small
          w-100
        "
      />

      <p class="">
      	2 person? Feel free to come without a reservation. We have plenty of seats available.
      </p>
  		<Select 
        :options="[
        	'4 person',
        	'6 person',
        	'8 person',
        	'10 person',
        	'10+ person'
        ]"
        placeholder="Select table"
        v-model:select="bookingTable" 
				class="bg-white pd-medium  bg-white t-black radius-small"
      />
      

        <p class="">
	      Leave your contact in messenger:
	    </p>

	    <div class="flex-nowrap gap-thin flex">
	      <Select 
	        :options="[
	          {name: 'Telegram',        value: 'telegram'}, 
	          {name: 'WhatsApp',        value: 'whatsapp'},
	          {name: 'Line',            value: 'line'}, 
	        ]"
	        v-model:select="bookingMessenger" 
	        placeholder="Messenger" 
	        size="small"
	        class="
	          bg-white
	          t-black
	          pd-medium
	          radius-small
	          w-8r
	        "
	      />

	      <Field 
	        v-model:field="bookingContact"     
	        placeholder="Enter your contact here"  
	        class="
	          bg-white
	          pd-medium
	          radius-small
	          w-100
	        "
	      />
	    </div>



      <p class="">
	      Select date and time of booking:
	    </p>

	   	<VueDatePicker 
     	 	v-model="bookingArrivalTime" 
     	 	:start-time="{ hours: 16, minutes: 0 }"
     	 	:min-date="new Date()"
     	 	:start-date="new Date()"
     	 	:min-time="{ hours: 16, minutes: 0 }"
     	 	:max-time="{ hours: 23, minutes: 0 }"
     	 	:partial-range="false" 
     	 	hide-offset-dates 
     	 	disable-month-year-select
     	 	class="mn-b-small " 
     	/>

	    <button @click="onSendNow" class="w-100 bg-black t-white button">Send</button>

	    	<Popup 
		      title="Read before booking" 
		      @close-popup="closePublicationPopup" 
		      :isPopupOpen="isPublicationPopup"
		      class="w-m-33r t-left pd-big bg-white radius-big"
		    >
					<p class="p-small mn-b-thin">We are able to seat a specific number of guests during service but we are only able to accommodate so many guests within each fifteen minute period in order to ensure that you receive the best level of service from us, and we do ask that once you have secured a reservation at a particular time that you adhere to that time. </p>

					<p class="p-small mn-b-thin">We strongly recommended that you reserve a table in advance by contacting the team directly. For parties over 10 a reservation and pre-order will be required.</p>

					<span class="d-block mn-b-small" itemprop="email">
            <a href="https://t.me/thecommunebar" target="_black" class="t-black">@thecommunebar</a>
          </span>

					<span class="d-block mn-b-small" itemprop="telephone">
            <a :href="'tel:' + t('contacts.telephone')" class="t-black">{{ t('contacts.telephone') }}</a>
          </span>

          <span class="d-block mn-b-small" itemprop="email">
            <a :href="'mailto:' + t('contacts.email')" class="t-black">{{ t('contacts.email') }}</a>
          </span>

   
					<p class="p-small mn-b-thin">If a reservation is made then the table is yours for the whole of service and we do not normally ask you to vacate a table. However, should you arrive more than 30 minutes after your booking time without forewarning, then you may lose your table to another group. </p>

					<p class="p-small mn-b-thin">We would respectfully ask that you are ready to be seated at the time of your reservation. Should you arrive after your booking time, we may need to hold you back to a later time to ensure the best possible level of service to all our guests. </p>

					<p class="p-small mn-b-thin">It is essential that we are notified of any specific dietary requirements prior to your visit to ensure that we can cater for you appropriately. If you have not informed us at the point of booking, please contact the restaurant to discuss your requirements with a member of our team. </p>

					<p class="p-small mn-b-thin">Tables are allocated on the day by the restaurant team and although we will do everything we can to allocate a table in your preferred area of the dining room, we cannot always guarantee this. </p>

				 	<Button :submit="onSubmit" :callback="redirectTo" class="w-100 bg-black t-white">Send Booking Request</Button>
	    </Popup>
    </div>

	</div>
</template>


<script setup>

	import { ref, onMounted } from 'vue';
	import { useI18n } from 'vue-i18n'
	import axios from 'axios';

	import { useStore } from '@/store';
  
  const store = useStore();
	const $axios = axios.create({baseURL: process.env.API_URL}) 



import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import { Select, Field, Popup, Button } from '@ozdao/prometheus-framework'


const bookingName = ref(null)
const bookingTable = ref(null)
const bookingMessenger = ref(null)
const bookingContact = ref(null)
const bookingArrivalTime = ref(null)

const { t } = useI18n({ 
	messages: {
	  en:{
	    "nye2024":{
	    	"subtitle": "FOR THE SPECIAL ONE’S",
	      "title":"BOOK THE TABLE AT PALM AND STARS",
	      "description":"If you're looking for a relaxing retreat that offers a perfect blend of modern lifestyle and tropical charm, look no further than The Commune.",
	      "booknow":"Book now"
	    }
	  }
	}
})




const isPublicationPopup = ref(false)

function openPublicationPopup() {
	isPublicationPopup.value = true;
}
function closePublicationPopup() {
	isPublicationPopup.value = false;
}

async function onSendNow() {
		if (!bookingName.value || !bookingTable.value || !bookingMessenger.value ||  !bookingContact.value || !bookingArrivalTime.value) {
		store.globals.setError({ response: {status: "Complete All Fields", data: { message: "Please note that all fields must be filled out to guarantee comprehensive and seamless processing."}}})
		return false
	} else {
		isPublicationPopup.value = true
	}
}
async function onSubmit() {

	gtag('event', 'book_table', {
    'event_category': 'conversion',
    'event_label': 'Book Table'
  });

  try {
    let application = {
      name: bookingName.value,
      table: bookingTable.value,
      messenger: bookingMessenger.value.name,
      contact: bookingContact.value,
      arrival: bookingArrivalTime.value,
    };

    const response = await $axios.post('/applications', application);
    return Promise.resolve(response.data);
  } catch (error) {
  	store.globals.setError(error)
    return Promise.reject(error);
  }
}

function redirectTo () {
	isPublicationPopup.value = false
}
</script>

<style>
	.dp__input, .dp__input:hover {
		border: 0 !important;
		border-radius: var(--small)  !important;
		padding: var(--medium)  !important;
		padding-left: var(--big) !important;
		padding-inline-start: none  !important;

	}
</style>
