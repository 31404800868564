<template>
  <!-- Cursors -->
  <!-- <div class="desktop-only cursor"></div> -->
  <!-- <div class="desktop-only cursor-follower"></div> -->

  <transition name="scaleTransition">
    <Loader v-if="!FirstUse && !page && !show" class="pos-fixed"/>
  </transition>

  <transition name="scaleTransition" mode="out-in">
    <div 
      v-if="!FirstUse && show" 
      ref="wrapperApp" 
      class="pos-relative pageSidebar"
      :class="{'pd-t-extra': MOBILE_APP === 'ios'}" 
    >

      <Header v-if="!MOBILE_APP"/>

      <router-view  
        v-slot="{ Component, route }" 
        :style="MOBILE_APP ?'padding-top: 6rem;':''" 
        :class="{'mn-b-extra': MOBILE_APP}" 
        class="z-index-3 min-h-100vh w-100" 
      >
      
        <Status 
          v-if="store.globals.state.error.show" 
          :status="store.globals.state.error.status" 
          :message="store.globals.state.error.data.message"
          class="z-index-5" 
        />

        <transition @before-enter="scrollTop" name="scaleTransition" mode="out-in">
          <component ref="page" v-if="show" :is="Component" :key="route.path"/>
        </transition>

        <Footer v-if="route.path.includes('/events')"/>
      </router-view>
     
    </div>
  </transition>
</template>

<script setup>
  /////////////////////////////
  // COMPONENT DEPENDENCIES
  /////////////////////////////
  import { computed, ref, onMounted,provide, inject } from 'vue'
  import { gsap } from "gsap/dist/gsap";
  import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
  // Router
  import { useRoute, useRouter } from 'vue-router'
  // Store
  import { useStore } from '@/store';
  // Components
  import Header from '@/components/partials/Header.vue';
  // REMOVE REFACTOR
  import SectionTable from '@/components/sections/SectionTable.vue';
  import SectionForm from '@/components/sections/SectionForm.vue'
  import SectionFeature from '@/components/sections/SectionFeature.vue';
  import Footer        from '@/components/partials/Footer.vue';
  // import Walkthrough from '@/components/sections/Walkthrough.vue';
  // Partials
  import Loader from '@/components/partials/Loader.vue'
  import Status from '@/components/partials/Status.vue'
  // GSAP DEPENDENCIES
  import initCursor from '@/animations/cursor.js';
  import init from '@/animations/globals.js';
  /////////////////////////////
  // FIRST USE
  /////////////////////////////
  const FirstUse = ref(false);

  import { Preferences } from '@capacitor/preferences';

  async function getFirstUse() {
    const ret = await Preferences.get({ key: 'first-use' });
    FirstUse.value = ret.value ? JSON.parse(ret.value) : false;
  }

  const updateFirstUse = (value) => {
    FirstUse.value = value;
  }
  /////////////////////////////
  // CREATED
  /////////////////////////////
  const route = useRoute()
  const router = useRouter()
  const store = useStore();
  // State
  let page = ref(null)
  let show = ref(false)
  let status = ref(null)
  gsap.registerPlugin(ScrollTrigger);
  /////////////////////////////
  // Methods
  /////////////////////////////
  function scrollTop(){
    document.getElementById('app').scrollIntoView();
  }
  /////////////////////////////
  // MOUNTED
  /////////////////////////////
  onMounted(async() => {
    await getFirstUse()

    init()
    initCursor()

    show.value = true
  })  
</script>

<style lang="scss">
  .scaleTransition-5px-enter-active,
  .scaleTransition-5px-leave-active {
    transform: translateY(0px);  
    opacity: 1;
    z-index: 1;
    transition: all 0.2s ease;
  }

  .scaleTransition-5px-enter-from,
  .scaleTransition-5px-leave-to {
    opacity: 0;
    z-index: 0;
    position: absolute;
    transform: translateY(-30px); 
    transition: all 0.2s ease;
  }

  .scaleTransition-enter-from,
  .scaleTransition-leave-to {
    opacity: 0;
    z-index: 0;
    position: absolute;
    width: inherit;
    height: inherit;
    display: block;
    transform: translateY(-30px); 
    transition: all 0.5s ease;
  }

  .scaleTransition-enter-active,
  .scaleTransition-leave-active {
    transform: translateY(0px);  
    opacity: 1;
    z-index: 1;
    transition: all 0.5s ease;
  }
  .scaleTransition-enter-from,
  .scaleTransition-leave-to {
    opacity: 0;
    z-index: 0;
    position: absolute;
    width: inherit;
    height: inherit;
    display: block;
    transform: translateY(-30px); 
    transition: all 0.5s ease;
  }

  .scaleIn-enter-active,
  .scaleIn-leave-active {
    // background: red;
    transition: all 0.5s ease;
    > section,div { transform-origin: 0 0; transform: translateZ(0px);  transition: all 0.5s ease; }
  }
  .scaleIn-enter-from,
  .scaleIn-leave-to {
    opacity: 0;
    > section,div { transform: translateZ(-30px);  transition: all 0.5s ease; }
    transform: scale(0.95);
  }

  // *,*:hover  {cursor: none !important; }

  .status-class {
    transform: translateY(-200px);
  }

  // .cursor {
  //   position: fixed;
  //   background-color: black;
  //    border:1px solid white;
  //   width: 10px;
  //   height: 10px;
  //   border-radius: 100%;
  //   z-index: 1;
  //   transition: 0.3s cubic-bezier(0.75, -1.27, 0.3, 2.33) transform,
  //   0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity;
  //   user-select: none;
  //   pointer-events: none;
  //   z-index: 10000;
  //   left: 50%;
  //   top: 50%;
  //   opacity: 0;
  //   transform: scale(0);
  //   visibility: hidden;

  //   &.visible {
  //     opacity: 1;
  //     transform: scale(1);
  //     visibility: visible;
  //   }    
  //   &.active {
  //     opacity: 0;
  //     transform: scale(0);
  //   }    
  //   &.normal {
  //     opacity: 1;
  //     transform: scale(1);
  //   }    
  // }
  
  // .cursor-follower {
  //   position: fixed;
    
  //   border:1px solid rgba(100,100,100,0.35);
  //   // background: rgba(100,100,100,0.25);
  //   width: 30px;
  //   height: 30px;
  //   border-radius: 100%;
  //   z-index: 1;
  //   transition: 0.3s cubic-bezier(0.75, -1.27, 0.3, 2.33) transform,
  //   0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity;
  //   user-select: none;
  //   pointer-events: none;
  //   z-index: 10000;
  //   left: 50%;
  //   top: 50%;
  //   transform: scale(0) translate(2px, 2px);
  //   opacity:0;
  //   visibility: hidden;
  //   &.visible {
  //     opacity: 1;
  //     transform: scale(1) translate(2px, 2px);
  //     visibility: visible;
  //     transition: 0.3s cubic-bezier(0.75, -1.27, 0.3, 2.33) transform,
  //   }    
  //   &.active {
  //     opacity: 1;
  //     border:1px solid rgba(255,255,255,1);
  //     transform: scale(3);
  //   }    
  //   &.normal {
  //     opacity: 1;
  //     transform: scale(1);
  //     border:1px solid rgba(255,255,255,0.2);
  //   }   
  // }
  .letter, .letter2 {
    display: inline-block;
    position: relative;
  }

  .bottom-navigation {
    display: block;
    position: fixed;
    bottom: 0rem; // This ensures it starts from the bottom


    width: 100%;

    a {
      opacity: 0.5;
    }

    .router-link-exact-active {
      opacity: 1;
    }

  }


</style>